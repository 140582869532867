import { NgModule } from '@angular/core';

import { RouterModule, Routes } from '@angular/router';
import { RegularPagesMainComponent } from './pages/regular-pages-main/regular-pages-main.component';
import { RegularPagesRegisterComponent } from './pages/regular-pages-register/regular-pages-register.component';
import { RegularPagesLoginComponent } from './pages/regular-pages-login/regular-pages-login.component';
import { RegularPagesComponent } from './regular-pages.component';
import { RegularPagesResetPasswordComponent } from './pages/regular-pages-reset-password/regular-pages-reset-password.component';
import { RegularPagesHotGamesComponent } from './pages/regular-pages-hot-games/regular-pages-hot-games.component';
import { RegularPagesTotoMarketsComponent } from './pages/regular-pages-toto-markets/regular-pages-toto-markets.component';
import { RegularPagesTotoDreamInterpretationsComponent } from './pages/regular-pages-toto-dream-interpretations/regular-pages-toto-dream-interpretations.component';
import { RegularPagesTotoResultsComponent } from './pages/regular-pages-toto-results/regular-pages-toto-results.component';
import { RegularPagesMaintenanceComponent } from './pages/regular-pages-maintenance/regular-pages-maintenance.component';
import { RegularPagesFaqComponent } from './pages/regular-pages-faq/regular-pages-faq.component';
import { RegularPagesPromotionComponent } from './pages/regular-pages-promotion/regular-pages-promotion.component';

const routes: Routes = [{
  path: "",
  canActivate: [],
  canActivateChild: [],
  component: RegularPagesComponent,
  children: [
    {
      path: "",
      component: RegularPagesMainComponent
    },
    {
      path: "login",
      component: RegularPagesLoginComponent
    },
    {
      path: "signup",
      component: RegularPagesRegisterComponent
    },
    {
      path: "reset-password",
      component: RegularPagesResetPasswordComponent
    },
    {
      path: "games/hot-games",
      component: RegularPagesHotGamesComponent
    },
    {
      path: "games/slots",
      component: RegularPagesHotGamesComponent
    },
    {
      path: "games/live-casino",
      component: RegularPagesHotGamesComponent
    },
    {
      path: "games/sports",
      component: RegularPagesHotGamesComponent
    },
    {
      path: "games/hot-games",
      component: RegularPagesHotGamesComponent
    },
    {
      path: "games/crash-game",
      component: RegularPagesHotGamesComponent
    },
    {
      path: "games/arcade",
      component: RegularPagesHotGamesComponent
    },
    {
      path: "games/e-sports",
      component: RegularPagesHotGamesComponent
    },
    {
      path: "games/cockfight",
      component: RegularPagesHotGamesComponent
    },
    {
      path: "lottery/markets",
      component: RegularPagesTotoMarketsComponent
    },
    {
      path: "lottery/dream-interpretation",
      component: RegularPagesTotoDreamInterpretationsComponent
    },
    {
      path: "lottery/results",
      component: RegularPagesTotoResultsComponent
    },
    {
      path:"maintenance",
      component: RegularPagesMaintenanceComponent
    },
    {
      path: "faq",
      component: RegularPagesFaqComponent
    },
    {
      path: "promotion",
      component: RegularPagesPromotionComponent
    }
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RegularPagesRoutingModule { }
