import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-regular-comp-get-in-touch',
  templateUrl: './regular-comp-get-in-touch.component.html',
  styleUrl: './regular-comp-get-in-touch.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink
  ],
})
export class RegularCompGetInTouchComponent {

}
