<span class="fixed blur-[200px] w-[600px] h-[600px] rounded-full top-1/2 start-1/2 ltr:-translate-x-1/2 rtl:translate-x-1/2 -translate-y-1/2 bg-gradient-to-tl from-red-600/20 to-violet-600/20 dark:from-red-600/40 dark:to-violet-600/40"></span>

<app-regular-comp-navbar/>






<section class="relative md:pt-16 py-16">
    <div class="container md:mt-16 mt-16">
        <div class="grid grid-cols-1 text-center mt-10">
            <h3 class="mb-4 md:text-3xl text-2xl md:leading-snug leading-snug font-semibold">Q&A</h3>

            <!-- <p class="text-slate-400 max-w-xl mx-auto">Semua permainan taruhan favorit anda. Mulai dari togel, kasino, hingga sports book, tergabung pada satu platform.</p> -->
        </div><!--end grid-->

        <div class="flex justify-center mt-5">
            <div class="lg:w-2/3 mt-2 mb-10">
                <app-regular-comp-faq/>
            </div>
        </div><!--end grid-->
    </div><!--end container-->

    <app-regular-comp-get-in-touch/>
</section> <!--end section-->

<app-regular-comp-footer/>
