import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RouterLink } from '@angular/router';
import { CarouselModule } from 'primeng/carousel';
import { RegularCompBackToHomeComponent } from '../../components/regular-comp-back-to-home/regular-comp-back-to-home.component';
import { RegularCompNavbarComponent } from '../../components/regular-comp-navbar/regular-comp-navbar.component';
import { RegularCompFooterComponent } from '../../components/regular-comp-footer/regular-comp-footer.component';
import { RegularCompFaqComponent } from '../../components/regular-comp-faq/regular-comp-faq.component';
import { RegularCompGetInTouchComponent } from '../../components/regular-comp-get-in-touch/regular-comp-get-in-touch.component';

import NftData from '../../../../data/nft.json'
import gamesData from '../../../../data/games.json'
import CreatorData from '../../../../data/creator.json'
import TotoData from '../../../../data/toto.json'

import { tns } from 'tiny-slider';

@Component({
  selector: 'app-regular-pages-main',
  templateUrl: './regular-pages-main.component.html',
  styleUrl: './regular-pages-main.component.scss',
  standalone: true,
  imports: [
    MatSlideToggleModule,
    CarouselModule,
    CommonModule,
    RouterLink,
    RegularCompBackToHomeComponent,
    RegularCompNavbarComponent,
    RegularCompFooterComponent,
    RegularCompFaqComponent,
    RegularCompGetInTouchComponent
  ]
})
export class RegularPagesMainComponent {

  constructor(){}
  creatorData = CreatorData
  totoData = TotoData

  nftData = NftData
  data:any = gamesData
  gamesData:any = this.data
  slider:any

  slider01New = [
    {url:"https://test.com/habanero",path:"assets/images/hero/habanero.png"},
    {url:"https://test.com/hacksaw",path:"assets/images/hero/hacksaw.png"},
    {url:"https://test.com/pgs",path:"assets/images/hero/pgs.png"},
    {url:"https://test.com/pragmatic",path:"assets/images/hero/pragmatic.png"},
    {url:"https://test.com/habanero",path:"assets/images/hero/habanero.png"},
    {url:"https://test.com/hacksaw",path:"assets/images/hero/hacksaw.png"},
    {url:"https://test.com/pgs",path:"assets/images/hero/pgs.png"},
    {url:"https://test.com/pragmatic",path:"assets/images/hero/pragmatic.png"},
    {url:"https://test.com/habanero",path:"assets/images/hero/habanero.png"},
    {url:"https://test.com/hacksaw",path:"assets/images/hero/hacksaw.png"},
    {url:"https://test.com/pgs",path:"assets/images/hero/pgs.png"},
    {url:"https://test.com/pragmatic",path:"assets/images/hero/pragmatic.png"},
  ]
  slider02New = [
    {url:"https://test.com/bulgaria",path:"assets/images/hero/bulgaria.png"},
    {url:"https://test.com/cuba",path:"assets/images/hero/cuba.png"},
    {url:"https://test.com/hungary",path:"assets/images/hero/hungary.png"},
    {url:"https://test.com/phuket",path:"assets/images/hero/phuket.png"},
    {url:"https://test.com/shanghai",path:"assets/images/hero/shanghai.png"},
    {url:"https://test.com/bulgaria",path:"assets/images/hero/bulgaria.png"},
    {url:"https://test.com/cuba",path:"assets/images/hero/cuba.png"},
    {url:"https://test.com/hungary",path:"assets/images/hero/hungary.png"},
    {url:"https://test.com/phuket",path:"assets/images/hero/phuket.png"},
    {url:"https://test.com/shanghai",path:"assets/images/hero/shanghai.png"},
    {url:"https://test.com/bulgaria",path:"assets/images/hero/bulgaria.png"},
    {url:"https://test.com/cuba",path:"assets/images/hero/cuba.png"},
    {url:"https://test.com/hungary",path:"assets/images/hero/hungary.png"},
    {url:"https://test.com/phuket",path:"assets/images/hero/phuket.png"},
    {url:"https://test.com/shanghai",path:"assets/images/hero/shanghai.png"},
  ]


  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.slider = tns({
      container: '.tiny-four-icon-item',
        controls: true,
        mouseDrag: true,
        loop: true,
        rewind: true,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: 3000,
        navPosition: "bottom",
        controlsText: ['<i class="mdi mdi-chevron-left "></i>', '<i class="mdi mdi-chevron-right"></i>'],
        nav: false,
        speed: 400,
        gutter: 0,
        responsive: {
            1025: {
                items: 4
            },
  
            992: {
                items: 3
            },
  
            767: {
                items: 2
            },
  
            320: {
                items: 1
            },
        },
    });
   }
  
}
