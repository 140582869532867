import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-regular-pages-maintenance',
  templateUrl: './regular-pages-maintenance.component.html',
  styleUrl: './regular-pages-maintenance.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
],
})
export class RegularPagesMaintenanceComponent {

}
