<app-regular-comp-navbar/>

<!-- Start Hero -->
<section class="relative table w-full py-36 bg-[url('../../assets/images/bg/bg1.jpg')] bg-bottom bg-no-repeat">
    <div class="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
    <div class="container">
        <div class="grid grid-cols-1 pb-8 text-center mt-10">
            <h3 class="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">{{nameForView}}</h3>

        </div><!--end grid-->
    </div><!--end container-->
    
    <div class="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
        <ul class="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
           <li class="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><a [routerLink]="'/'">Test</a></li>
            <li class="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white" aria-current="page">Permainan - {{nameForView}}</li>
        </ul>
    </div>
</section><!--end section-->

<div class="relative">
    <div class="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
        <svg class="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!-- End Hero -->

 <!-- Start -->
 <section class="relative md:py-24 py-16">
    <div class="container">
        <div class="grid grid-cols-1 items-center gap-[30px]">
            <div class="filters-group-wrap text-center">
                <!-- <div class="filters-group">
                    <ul class="mb-0 list-none container-filter-box filter-options">
                        <li class="inline-block font-medium text-base mx-1.5 mb-3 py-1 px-3 cursor-pointer relative text-slate-400 border border-gray-100 dark:border-gray-700 rounded-full transition duration-500" [ngClass]="selectedCategory === null ? 'active' :'' " (click)="matchCategory(null)" data-group="all"><i class="uil uil-browser"></i>  All</li>
                        <li class="inline-block font-medium text-base mx-1.5 mb-3 py-1 px-3 cursor-pointer relative text-slate-400 border border-gray-100 dark:border-gray-700 rounded-full transition duration-500" [ngClass]="selectedCategory === 'games' ? 'active' :'' " (click)="matchCategory('games')" data-group="games"><i class="uil uil-volleyball"></i> Games</li>
                        <li class="inline-block font-medium text-base mx-1.5 mb-3 py-1 px-3 cursor-pointer relative text-slate-400 border border-gray-100 dark:border-gray-700 rounded-full transition duration-500" [ngClass]="selectedCategory === 'art' ? 'active' :'' " (click)="matchCategory('art')" data-group="art"><i class="uil uil-chart-pie-alt"></i> Art</li>
                        <li class="inline-block font-medium text-base mx-1.5 mb-3 py-1 px-3 cursor-pointer relative text-slate-400 border border-gray-100 dark:border-gray-700 rounded-full transition duration-500" [ngClass]="selectedCategory === 'music' ? 'active' :'' " (click)="matchCategory('music')" data-group="music"><i class="uil uil-music"></i> Music</li>
                        <li class="inline-block font-medium text-base mx-1.5 mb-3 py-1 px-3 cursor-pointer relative text-slate-400 border border-gray-100 dark:border-gray-700 rounded-full transition duration-500" [ngClass]="selectedCategory === 'video' ? 'active' :'' " (click)="matchCategory('video')" data-group="video"><i class="uil uil-camera-plus"></i> Video</li>
                        <li class="inline-block font-medium text-base mx-1.5 mb-3 py-1 px-3 cursor-pointer relative text-slate-400 border border-gray-100 dark:border-gray-700 rounded-full transition duration-500" [ngClass]="selectedCategory === 'memes' ? 'active' :'' " (click)="matchCategory('memes')" data-group="memes"><i class="uil uil-rocket"></i> Memes</li>
                    </ul>
                </div> -->
            </div>
        </div><!--grid-->

        <div id="grid" class="md:flex justify-center mx-auto mt-4">
            <div class="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px]">
                <div *ngFor="let item of filteredData" class="group relative overflow-hidden p-2 rounded-lg bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:shadow-md dark:shadow-md hover:dark:shadow-gray-700 transition-all duration-500 hover:-mt-2 h-fit">
                    <div class="relative overflow-hidden">
                        <div class="relative overflow-hidden rounded-lg">
                            <img [src]="item.image" class="rounded-lg shadow-md dark:shadow-gray-700 group-hover:scale-110 transition-all duration-500" alt="">
                        </div>
    
                        <div class="absolute -bottom-20 group-hover:bottom-1/2 group-hover:translate-y-1/2 start-0 end-0 mx-auto text-center transition-all duration-500">
                            <a [routerLink]="['/item-detail', item.id]" class="btn btn-sm rounded-full bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white"><i class="mdi mdi-controller"></i> Mainkan</a>                                
                        </div>
    
                    </div>
    
                    <div class="mt-3">

    
                        <div class="my-3">
                            <a [routerLink]="['/item-detail', item.id]" class="font-semibold text-slate-400 hover:text-violet-600">Hot Games</a>
                        </div>
    
                        <div class="flex center p-2 bg-gray-50 dark:bg-slate-800 rounded-lg shadow dark:shadow-gray-700">
                            <div>
                                <span class="text-[16px] font-semibold block"><i class="mdi mdi-controller"></i> {{item.title}}</span>
                            </div>
                        </div>
                    </div>
                </div><!--end content-->
            </div><!--end grid-->
        </div><!--end grid-->

        <div class="grid md:grid-cols-12 grid-cols-1 mt-8">
            <div class="md:col-span-12 text-center">
                <nav>
                    <ul class="inline-flex items-center -space-x-px">
                        <li>
                            <a [routerLink]="" (click)="$event.preventDefault()" class="w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-slate-400 bg-white dark:bg-slate-900 hover:text-white shadow-sm dark:shadow-gray-700 hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600">
                                <i class="uil uil-angle-left text-[20px]"></i>
                            </a>
                        </li>
 
                        <li>
                            <a [routerLink]="" (click)="$event.preventDefault()" aria-current="page" class="z-10 w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-white bg-violet-600 shadow-sm dark:shadow-gray-700">1</a>
                        </li>

                        <li>
                            <a [routerLink]="" (click)="$event.preventDefault()" class="w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-slate-400 bg-white dark:bg-slate-900 hover:text-white shadow-sm dark:shadow-gray-700 hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600">
                                <i class="uil uil-angle-right text-[20px]"></i>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div><!--end grid-->
    </div><!--end container-->
</section><!--end section-->
<!-- End -->

<app-regular-comp-footer/>

<app-regular-comp-back-to-home/>