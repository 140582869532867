<!-- Start -->
<section class="relative h-screen flex items-center justify-center text-center ">
    <div class="absolute inset-0 bg-slate-900/40"></div>
    <div class="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
    <div class="container center-cont">
        <div class="grid grid-cols-1">
            <img [src]="'assets/images/logo-icon.png'" class="mx-auto" alt="">
            <h1 class="text-white mb-6 mt-10 md:text-5xl text-3xl font-bold">Situs dalam proses maintenance</h1>
            <p class="text-white/70 max-w-xl mx-auto">Mohon maaf atas ketidaknyamanan-nya. Kami akan segera beroperasi kembali.</p>
        </div><!--end grid-->



    </div><!--end container-->
</section><!--end section-->
<!-- End -->