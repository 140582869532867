<span class="fixed blur-[200px] w-[600px] h-[600px] rounded-full top-1/2 start-1/2 ltr:-translate-x-1/2 rtl:translate-x-1/2 -translate-y-1/2 bg-gradient-to-tl from-red-600/20 to-violet-600/20 dark:from-red-600/40 dark:to-violet-600/40"></span>

<app-regular-comp-navbar/>


<!-- Start -->
<section class="relative pt-24 overflow-hidden">
    <!-- <div class="grid grid-cols-1 text-center mt-10">
        <h3 class="md:text-[23px] text-[26px] font-semibold my-10">Permainan/Toto Favorit</h3>
    </div> -->
    <!--end grid-->
    <div class="container-fluid relative pt-8">
        <div class="grid grid-cols-1">
            <div class="slider relative overflow-hidden m-auto mb-4 before:content-[''] before:absolute before:top-0 before:start-0 before:z-2 after:content-[''] after:absolute after:top-0 after:end-0 after:z-2">
                <div class="slide-track flex items-center">
                    <div *ngFor="let item of slider01New" class="slide h-auto md:w-[360px] w-72 mx-2">
                        <div class="group relative overflow-hidden rounded-lg shadow dark:shadow-gray-800">
                            <img [src]="[item.path]" class="rounded-lg shadow-md dark:shadow-gray-700 group-hover:scale-110 transition-all duration-500" alt="">

                            <div class="absolute -bottom-20 group-hover:bottom-1/2 group-hover:translate-y-1/2 start-0 end-0 mx-auto text-center transition-all duration-500">
                                <a [routerLink]="[item.url]" class="btn btn-sm rounded-full bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white"><i class="mdi mdi-cards-playing"></i> Mainkan</a>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div><!--end slider-->

            <div class="slider2 relative overflow-hidden m-auto before:content-[''] before:absolute before:top-0 before:start-0 before:z-2 after:content-[''] after:absolute after:top-0 after:end-0 after:z-2">
                <div class="slide-track flex items-center">
                    <div *ngFor="let item of slider02New" class="slide h-auto md:w-[360px] w-72 mx-2">
                        <div class="group relative overflow-hidden rounded-lg shadow dark:shadow-gray-800">
                            <img [src]="[item.path]" class="rounded-lg shadow-md dark:shadow-gray-700 group-hover:scale-110 transition-all duration-500" alt="">

                            <div class="absolute -bottom-20 group-hover:bottom-1/2 group-hover:translate-y-1/2 start-0 end-0 mx-auto text-center transition-all duration-500">
                                <a [routerLink]="[item.url]" class="btn btn-sm rounded-full bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white"><i class="mdi mdi-web"></i> Kunjungi</a>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div><!--end slider2-->
        </div><!--end grid-->
    </div><!--end container-->
</section><!--end section-->
<!-- End -->

<section class="relative md:pt-10 pt-10 overflow-hidden">
    <div class="container pt-10">
        <div class="grid grid-cols-1 justify-center text-center mt-10">
            <div class="relative">
                <div class="relative mb-5">
                    <h1 class="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl">Toto dan permainan taruhan favoritmu<br>hanya pada  <span class="bg-gradient-to-l from-red-600 to-violet-600 text-transparent bg-clip-text">satu platform</span></h1>

                    <div class="overflow-hidden after:content-[''] after:absolute after:h-10 after:w-10 after:bg-violet-600/10 dark:after:bg-violet-600/30 after:-top-[50px] after:start-[30%] after:-z-1 after:rounded-lg after:animate-[spin_10s_linear_infinite]"></div>

                    <div class="overflow-hidden after:content-[''] after:absolute after:h-10 after:w-10 after:bg-violet-600/20 dark:after:bg-violet-600/40 after:bottom-[0] after:end-[15%] after:-z-1 after:rounded-full after:animate-ping"></div>
                </div>
                <p class="text-slate-400 dark:text-white/70 text-lg max-w-xl mx-auto">Semua permainan taruhan favorit anda. Mulai dari togel, kasino, hingga sports book, tergabung pada satu platform yang telah bersertifikat</p>
            
     
            </div>
        </div><!--end grid-->

        <div class="relative animate-[spin_30s_linear_infinite] -z-1">
            <span class="after:absolute after:start-0 after:bottom-1/2 after:translate-y-1/2 after:h-2 after:w-8 after:rounded-md after:bg-violet-600/20 relative after:z-10"></span>
            <span class="after:absolute after:start-0 after:bottom-1/2 after:translate-y-1/2 after:rotate-90 after:h-2 after:w-8 after:rounded-md after:bg-violet-600/20 relative after:z-10"></span>
        </div>
    </div><!--end container-->


</section><!--end section-->

<section class="relative md:pt-36 pt-24 overflow-hidden">
    <div class="container md:mt-18 mt-12">
        <div class="md:flex justify-between items-center">
            <div class="md:w-10/12 md:text-start text-center">
                <h3 class="md:text-[30px] text-[26px] font-semibold">Toto</h3>
            </div>
            <div class="md:w-2/12 text-end md:block hidden">
                <a [routerLink]="'/lottery/results'" class="btn btn-link text-[16px] font-medium hover:text-violet-600 after:bg-violet-600 duration-500 ease-in-out">Selengkapnya <i class="uil uil-arrow-right"></i></a>
            </div>
        </div><!--end grid-->

        <div class="grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-3 mt-6">
            <div *ngFor="let item of totoData" class="group relative overflow-hidden bg-white dark:bg-slate-900 rounded-md shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-800 ease-in-out duration-500 hover:-mt-2 h-fit">
                <div class="flex justify-between items-center p-3">
                    <div class="flex items-center">
                        <div class="relative inline-block">
                            <img [src]="item.image" class="h-16 rounded-md" alt="">
                        </div>

                        <div class="ms-3">
                            <a [routerLink]="['/creator-profile',item.id]" class="font-semibold block hover:text-violet-600">{{item.title}}</a>
                            <span class="text-slate-400 text-sm block mt-1">Tutup : {{item.close}}</span>
                        </div>
                    </div>

                    <a [routerLink]="" (click)="$event.preventDefault()" class="btn btn-icon rounded-full px-8 bg-violet-600/5 hover:bg-violet-600 border-violet-600/10 hover:border-violet-600 text-violet-600 hover:text-white">Pasang</a>
                </div>

                <div class="p-2 border-t text-center dark:border-t-gray-700">
                    <div class="text-3xl font-bold tracking-[.25em]">
                       {{item.latest_num}}
                    </div>
                    <div class="text-slate-400 text-sm ">
                        {{item.latest_date}}
                     </div>
                </div>
            </div>
            <!-- End content -->
        </div><!--end grid-->

        <div class="grid grid-cols-1 mt-6 md:hidden">
            <div class="text-center">
                <a [routerLink]="'/collections'" class="btn btn-link text-[16px] font-medium hover:text-violet-600 after:bg-violet-600 duration-500 ease-in-out">Selengkapnya <i class="uil uil-arrow-right"></i></a>
            </div>
        </div><!--end grid-->

    </div><!--end container-->
</section><!--end section-->

<section class="relative md:pt-36 pt-24 overflow-hidden">
    <div class="container md:mt-18 mt-12">
        <div class="md:flex justify-between items-center">
            <div class="md:w-10/12 md:text-start text-center">
                <h3 class="md:text-[30px] text-[26px] font-semibold">Permainan</h3>
            </div>
            <div class="md:w-2/12 text-end md:block hidden">
                <a [routerLink]="'/games/hot-games'" class="btn btn-link text-[16px] font-medium hover:text-violet-600 after:bg-violet-600 duration-500 ease-in-out">Selengkapnya <i class="uil uil-arrow-right"></i></a>
            </div>
        </div><!--end grid-->

        <div class="grid relative grid-cols-1 mt-10">
            <div class="tiny-four-icon-item">
                <div *ngFor="let item of gamesData" class="tiny-slide">
                    <div class="group relative overflow-hidden bg-white dark:bg-slate-900 rounded-lg p-3 shadow dark:shadow-gray-800 ease-in-out duration-500 m-2">
                        <img [src]="item.image" class="rounded-lg" alt="">

                        <div class="relative p-4 -mt-14">
                            <div class="relative inline-block">
                            </div>

                            <div class="mt-12">
                                <a [routerLink]="'/explore-one'" class="font-semibold block text-[18px] hover:text-violet-600">{{item.title}}</a>
                            </div>
                        </div>
                    </div>
                    <!-- End Content -->
                </div>
            </div>
        </div><!--end grid-->

        <div class="grid grid-cols-1 mt-6 md:hidden">
            <div class="text-center">
                <a [routerLink]="'/collections'" class="btn btn-link text-[16px] font-medium hover:text-violet-600 after:bg-violet-600 duration-500 ease-in-out">Selengkapnya <i class="uil uil-arrow-right"></i></a>
            </div>
        </div><!--end grid-->
    </div><!--end container-->
</section><!--end section-->








<!-- <section class="relative md:pt-16 py-16">
    <div class="container md:mt-16 mt-16">
        <div class="grid grid-cols-1 text-center mt-10">
            <h3 class="mb-4 md:text-3xl text-2xl md:leading-snug leading-snug font-semibold">Q&A</h3> -->

            <!-- <p class="text-slate-400 max-w-xl mx-auto">Semua permainan taruhan favorit anda. Mulai dari togel, kasino, hingga sports book, tergabung pada satu platform.</p> -->
        <!-- </div> -->
        <!--end grid-->

        <!-- <div class="flex justify-center mt-5">
            <div class="lg:w-2/3 mt-2 mb-10">
                <app-regular-comp-faq/>
            </div>
        </div> -->
        <!--end grid-->
    <!-- </div> -->
    <!--end container-->

    <!-- <app-regular-comp-get-in-touch/>
</section>  -->
<!--end section-->

<app-regular-comp-footer/>
