import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { RegularCompBackToHomeComponent } from '../../components/regular-comp-back-to-home/regular-comp-back-to-home.component';

@Component({
  selector: 'app-regular-pages-register',
  templateUrl: './regular-pages-register.component.html',
  styleUrl: './regular-pages-register.component.scss',
  standalone: true,
  imports : [
    CommonModule,
    RouterLink,
    RegularCompBackToHomeComponent
  ]
})
export class RegularPagesRegisterComponent {

}
