import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { RegularCompNavbarComponent } from '../../components/regular-comp-navbar/regular-comp-navbar.component';
import { RegularCompFooterComponent } from '../../components/regular-comp-footer/regular-comp-footer.component';
import { RegularCompBackToHomeComponent } from '../../components/regular-comp-back-to-home/regular-comp-back-to-home.component';

import gamesData from '../../../../data/games.json'

@Component({
  selector: 'app-regular-pages-hot-games',
  templateUrl: './regular-pages-hot-games.component.html',
  styleUrl: './regular-pages-hot-games.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    RegularCompNavbarComponent,
    RegularCompFooterComponent,
    RegularCompBackToHomeComponent
  ]
})
export class RegularPagesHotGamesComponent implements OnInit{
  data:any = gamesData

  gamesData:any = this.data

  filteredData = this.gamesData;

  href : any = ""
  nameForView = ""
  nameForFiewDict : any = {
    "/games/hot-games":"Hot Games",
    "/games/slots":"Slots",
    "/games/live-casino":"Live Casino",
    "/games/sports":"Olahraga",
    "/games/crash-game":"Crash Game",
    "/games/arcade":"Arcade",
    "/games/e-sports":"E-Sports",
    "/games/cockfight":"Sabung Ayam",
  }


  constructor(private router: Router) { }

  ngOnInit(): void {
    this.href = this.router.url;
    console.log(this.router.url);
    this.nameForView = this.nameForFiewDict[this.href]
    console.log(this.nameForView);
    
  }


}
