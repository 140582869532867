import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RouterLink } from '@angular/router';
import { CarouselModule } from 'primeng/carousel';
import { RegularCompBackToHomeComponent } from '../../components/regular-comp-back-to-home/regular-comp-back-to-home.component';
import { RegularCompNavbarComponent } from '../../components/regular-comp-navbar/regular-comp-navbar.component';
import { RegularCompFooterComponent } from '../../components/regular-comp-footer/regular-comp-footer.component';
import { RegularCompFaqComponent } from '../../components/regular-comp-faq/regular-comp-faq.component';
import { RegularCompGetInTouchComponent } from '../../components/regular-comp-get-in-touch/regular-comp-get-in-touch.component';

@Component({
  selector: 'app-regular-pages-faq',
  templateUrl: './regular-pages-faq.component.html',
  styleUrl: './regular-pages-faq.component.scss',
  standalone: true,
  imports: [
    MatSlideToggleModule,
    CarouselModule,
    CommonModule,
    RouterLink,
    RegularCompBackToHomeComponent,
    RegularCompNavbarComponent,
    RegularCompFooterComponent,
    RegularCompFaqComponent,
    RegularCompGetInTouchComponent
  ]
})
export class RegularPagesFaqComponent {

}
