<footer class="footer bg-dark-footer relative text-gray-200 dark:text-gray-200 mt-24">
    <div class="container">
        <div class="grid grid-cols-1">
            <div class="relative py-20 mt-20">
                <!-- Subscribe -->
                <div class="relative w-full">
                    <!-- <div class="relative -top-40 bg-white dark:bg-slate-900 lg:px-8 px-6 py-10 rounded-xl shadow dark:shadow-gray-800 overflow-hidden">
                        <div class="grid md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
                            <div class="md:text-start text-center z-1">
                                <h3 class="text-[26px] font-semibold text-slate-900 dark:text-white">Subscribe to Newsletter!</h3>
                                <p class="text-slate-400 max-w-xl mx-auto">Subscribe to get latest updates and information.</p>
                            </div>

                            <div class="subcribe-form z-1">
                                <form class="relative max-w-lg md:ms-auto">
                                    <input type="email" id="subcribe" name="email" class="pt-4 pe-40 pb-4 ps-6 w-full h-[50px] outline-none text-slate-900 dark:text-white rounded-full bg-white dark:bg-slate-900 shadow dark:shadow-gray-800" placeholder="Enter your email :">
                                    <button type="submit" class="btn absolute top-[2px] end-[3px] h-[46px] bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-full">Subscribe</button>
                                </form>
                            </div>
                        </div>

                        <div class="absolute -top-5 -start-5">
                            <div class="uil uil-envelope lg:text-[150px] text-7xl text-slate-900/5 dark:text-white/5 -rotate-45"></div>
                        </div>

                        <div class="absolute -bottom-5 -end-5">
                            <div class="uil uil-pen lg:text-[150px] text-7xl text-slate-900/5 dark:text-white/5"></div>
                        </div>
                    </div> -->

                    <div class="grid md:grid-cols-12 grid-cols-1 gap-[30px] -mt-24">
                        <div class="lg:col-span-4 md:col-span-12">
                            <a [routerLink]="" (click)="$event.preventDefault()" class="text-[22px] focus:outline-none">
                                <img [src]="'assets/images/logo-white.png'" alt="">
                            </a>
                            <p class="mt-6 text-gray-300">Semua permainan taruhan favorit anda. Mulai dari togel, kasino, hingga sports book, tergabung pada 
                                <span class="bg-gradient-to-l from-red-400 to-violet-400 text-transparent bg-clip-text">
                                    satu platform
                                </span>
                                .</p>
                    
                        </div><!--end col-->
                
                        <div class="lg:col-span-2 md:col-span-4">
                            <h5 class="tracking-[1px] text-lg text-gray-100 font-semibold">Test</h5>
                            <ul class="list-none footer-list mt-6">
                                <li *ngFor="let item of link1" class="mt-[10px] first:mt-0 ms-0"><a [routerLink]="item.link" class="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out"><i class="uil uil-angle-right-b me-1"></i> {{item.title}}</a></li>
                            </ul>
                        </div><!--end col-->
                
                        <div class="lg:col-span-3 md:col-span-4">
                            <h5 class="tracking-[1px] text-lg text-gray-100 font-semibold">Pintasan</h5>
                            <ul class="list-none footer-list mt-6">
                                <li *ngFor="let item of link2" class="mt-[10px] first:mt-0 ms-0"><a [routerLink]="item.link" class="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out"><i class="uil uil-angle-right-b me-1"></i> {{item.title}}</a></li>
                            </ul>
                        </div><!--end col-->

                        <div class="lg:col-span-3 md:col-span-4">
                            <h5 class="tracking-[1px] text-lg text-gray-100 font-semibold">Download Aplikasi Test :</h5>

                            <ul class="list-none mt-6">
                                <li class="inline"><img [src]="'assets/images/app.png'" class="h-9 inline-block" alt=""></li>
                                <li class="inline"><img [src]="'assets/images/playstore.png'" class="h-9 inline-block" alt=""></li>
                            </ul>

                            <div class="mt-6">
                                <h5 class="tracking-[1px] text-lg text-gray-100 font-semibold">Kontak Kami</h5>

                                <div class="flex mt-6">
                                    <i data-feather="mail" class="w-5 h-5 text-violet-600 me-3 mt-1"></i>
                                    <div class="">
                                        <a href="mailto:contact@example.com" class="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out">contact&#64;example.com</a>
                                    </div>
                                </div>
                        
                                <div class="flex mt-6">
                                    <i data-feather="phone" class="w-5 h-5 text-violet-600 me-3 mt-1"></i>
                                    <div class="">
                                        <a href="tel:+152534-468-854" class="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out">+152 534-468-854</a>
                                    </div>
                                </div>
                            </div>
                        </div><!--end col-->
                    </div><!--end grid-->
                </div>
                <!-- Subscribe -->
            </div>
        </div>
    </div><!--end container-->

    <div class="py-[30px] px-0 border-t border-gray-800 dark:border-gray-700">
        <div class="container text-center">
            <div class="grid md:grid-cols-2 items-center gap-6">
                <div class="md:text-start text-center">
                    <p class="mb-0 text-gray-300">©2024 Test. All rights reserved | 18+</p>
                </div>

                <ul class="list-none md:text-end text-center">
                    <li *ngFor="let item of social" class="inline"><a [href]="item.link" target="_blank" class="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><i class="align-middle" [ngClass]="item.icon" title="Buy Now"></i></a></li>
                </ul><!--end icon-->
            </div><!--end grid-->
        </div><!--end container-->
    </div>
</footer>