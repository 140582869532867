import { Component } from '@angular/core';
import { RegularCompBackToHomeComponent } from '../../components/regular-comp-back-to-home/regular-comp-back-to-home.component';
import { RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';



@Component({
  selector: 'app-regular-pages-login',
  templateUrl: './regular-pages-login.component.html',
  styleUrl: './regular-pages-login.component.scss',
  standalone: true,
  imports:[
    CommonModule,
    RouterLink,
    RegularCompBackToHomeComponent,
  ]
})
export class RegularPagesLoginComponent {

}
