import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-regular-comp-toto-number',
  templateUrl: './regular-comp-toto-number.component.html',
  styleUrl: './regular-comp-toto-number.component.scss',
  standalone: true
})
export class RegularCompTotoNumberComponent {
  @Input() class = 'md:hidden flex gap-1'
  @Input() w = '10'
  @Input() h = '10'
  @Input() pClass = 'w-10 h-10'
  @Input() value = '0000'

  parseValue(v: string): string[] {
    return v.split('').map((v, i) => v === '0' ? '' : v)
  }
}
