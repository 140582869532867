import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import * as feather from 'feather-icons'

@Component({
  selector: 'app-regular-comp-back-to-home',
  templateUrl: './regular-comp-back-to-home.component.html',
  styleUrl: './regular-comp-back-to-home.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink
  ],
})
export class RegularCompBackToHomeComponent {
  ngAfterViewInit(): void {
    feather.replace()
  }
}
