<app-regular-comp-navbar/>

<!-- Start Hero -->
<section class="relative table w-full py-36 bg-[url('../../assets/images/bg/bg1.jpg')] bg-bottom bg-no-repeat">
    <div class="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
    <div class="container">
        <div class="grid grid-cols-1 pb-8 text-center mt-10">
            <h3 class="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">Toto</h3>
        </div><!--end grid-->
    </div><!--end container-->
    
    <div class="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
        <ul class="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
           <li class="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><a [routerLink]="'/'">Test</a></li>
            <li class="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white" aria-current="page">Toto - Hasil</li>
        </ul>
    </div>
</section><!--end section-->
<div class="relative">
    <div class="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
        <svg class="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!-- End Hero -->



<div class="mx-auto z-0 !w-full !max-w-[1150px] overflow-auto mt-5 bg-slate-800 rounded-lg">

    <div class="flex justify-between border-b border-slate-600">
        <div [routerLink]="['/lottery/results']"
            class="text-center w-full hover:bg-purple-950/40 transition-all ease-in duration-200 rounded-lg cursor-pointer font-bold p-2"
           >
            Hasil
        </div>
        <div [routerLink]="['/lottery/markets']"
            class="text-center w-full hover:bg-purple-950/40 transition-all ease-in duration-200 rounded-lg cursor-pointer font-bold p-2"
            routerLinkActive="bg-purple-950">
            Pasaran
        </div>
        <div [routerLink]="['/lottery/dream-interpretation']"
            class="text-center w-full hover:bg-purple-950/40 transition-all ease-in duration-200 rounded-lg cursor-pointer font-bold p-2"
            routerLinkActive="bg-purple-950">
            Buku Mimpi
        </div>
    </div>


    <div data-pane="tabpane" class="mx-auto" id="result">
        <div class="flex justify-between p-3">
            <div>
                <h2 class="border-l-4 border-purple-600 pl-3 uppercase font-bold text-lg">Hasil terakhir</h2>
            </div>
            <div class="flex gap-1 items-center hover:text-purple-800 cursor-pointer transition-all duration-300 text-sm"
                routerLink="/lottery/history" >
                <p class="mb-2">Statistik </p>
                <mat-icon class="material-symbols-outlined mb-0 text-[17px]">arrow_forward_ios</mat-icon>
            </div>
        </div>

        <div class="mt-3">
            <mat-accordion>
                <mat-expansion-panel class="bg-slate-800 cursor-pointer text-white p-2 px-1 border-b border-slate-700"
                    (opened)="expansionPanelOpenState.set(true)" (closed)="expansionPanelOpenState.set(false)"
                    *ngFor="let item of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20];">

                    <mat-expansion-panel-header>
                        <mat-panel-title class="text-white">
                            <div class="flex justify-between items-center">
                                <a class="flex items-center gap-3 cursor-pointer w-full"
                                    href="https://sydneylotto.direct" target="_blank">
                                    <div class="min-w-[45px]">
                                        <img width="45" class="rounded-lg"
                                            src="https://vxbrkq1luxtv.gpa2glsjhw.xyz/ajax/image/PRC/1841_agdraw_SYDNEY_LOTTO/getImage/10PRC_10MAC_LOGO%20SDY%20LOTTO.jpeg"
                                            alt="">
                                    </div>
                                    <div>
                                        <p class="font-bold md:text-[15px] text-xs underline mb-1 md:mb-0 text-nowrap">
                                            SYDNEY LOTTO
                                        </p>
                                        <app-regular-comp-toto-number class="md:hidden flex gap-1" pClass="w-7 h-7" value="1878" />
                                    </div>
                                </a>
                            </div>
                        </mat-panel-title>
                        <mat-panel-description class="text-white">
                            <p class="font-bold hidden md:block">{{item}}</p>
                            <p class="font-bold w-full md:text-sm text-xs md:text-center text-end">Sabtu, 26/10/2024
                                14:05</p>

                            <!-- desktop show it -->
                            <app-regular-comp-toto-number class="md:flex hidden gap-2 w-full" pClass="w-10 h-10" value="1878" />
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <!-- 1 -->
                    <div class="flex justify-between items-center py-3 border-b border-slate-700"
                        *ngFor="let item of [1,2,3,4,5]">
                        <a class="flex items-center gap-3 cursor-pointer w-full" href="https://sydneylotto.direct"
                            target="_blank">
                            <div class="min-w-[45px]">
                                <!-- id for mobile -->
                                <p class="font-bold md:hidden block">{{item}}</p>
                            </div>
                            <div>
                                <p class="font-bold md:text-[15px] text-xs underline mb-1 md:mb-0"></p>
                                <app-regular-comp-toto-number class="md:hidden flex gap-1" pClass="h-7 w-7" value="1878" />

                            </div>
                        </a>

                        <!-- id for desktop -->
                        <p class="font-bold hidden md:block">{{item}}</p>
                        <p class="font-bold w-full md:text-sm text-xs md:text-center text-end">Sabtu, 26/10/2024
                            14:05</p>

                        <!-- desktop show it -->
                        <app-regular-comp-toto-number class="hidden md:flex gap-2 w-full" pClass="w-10 h-10" value="1878" />
                    </div>

                    <!-- 2 -->
                    <div class="flex justify-between items-center py-3 border-b border-slate-700">
                        <a class="hidden md:flex items-center gap-3 cursor-pointer w-full"
                            href="https://sydneylotto.direct" target="_blank"></a>


                        <div class="block text-nowrap w-full text-[12px] md:text-sm ">
                            <button mat-button class="text-white underline" routerLink="/lottery/history"
                                [queryParams]="{market: 'TOTOMACAU_1600'}">Lihat Semua</button>
                        </div>

                        <!-- desktop show it -->
                        <div class="flex gap-2 w-full">
                            <div class="text-[12px] md:text-sm text-nowrap">
                                <p>Tutup: 13.30 WIB</p>
                                <p class="flex items-center text-start"><mat-icon
                                        class="material-symbols-outlined text-sm">schedule</mat-icon> <span
                                        class="-ml-2">17h 8m</span></p>
                            </div>
                            <div class="flex justify-end">
                                <button mat-flat-button
                                    class="rounded-lg bg-yellow-600 text-xs md:text-sm p-1 md:p-3">Pasang</button>
                            </div>
                        </div>
                    </div>

                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>

</div>


<app-regular-comp-footer/>