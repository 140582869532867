 <!-- Start Navbar -->
 <nav id="topnav" class="defaultscroll is-sticky" [ngClass]="scrolled ? 'nav-sticky' : ''">
    <div class="container">
        <div class="flex justify-between">

        <!-- Logo container-->
        <ng-container *ngIf="navLight; else elseTemplate">
            <a class="logo ps-0" [routerLink]="'/'">
                <img [src]="'assets/images/logo-icon-28.png'" class="inline-block sm:hidden"  alt="">
                <div class="sm:block hidden">
                    <span class="inline-block dark:hidden">
                        <img [src]="'assets/images/logo-dark.png'" class="l-dark h-7" alt="">
                        <img [src]="'assets/images/logo-light.png'" class="l-light h-7" alt="">
                    </span>
                    <img [src]="'assets/images/logo-white.png'" height="24" class="hidden dark:inline-block" alt="">
                </div>
            </a>
         </ng-container>
         <ng-template #elseTemplate>
             <a class="logo ps-0" [routerLink]="'/'">
                 <img [src]="'assets/images/logo-icon-28.png'" class="inline-block sm:hidden"  alt="">
                 <div class="sm:block hidden">
                     <img [src]="'assets/images/logo-dark.png'" class="inline-block dark:hidden h-7" alt="">
                     <img [src]="'assets/images/logo-white.png'" class="hidden dark:inline-block h-7" alt="">
                 </div>
             </a>
         </ng-template>
         
        <!-- End Logo container-->

        <div class="menu-extras">
            <div class="menu-item">
                <!-- Mobile menu toggle-->
                <a  class="navbar-toggle" [ngClass]="open ? 'open' : ''" id="isToggle" (click)="toggleMenu($event)">
                    <div class="lines">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </a>
                <!-- End mobile menu toggle-->
            </div>
        </div>

        <!--Login button Start-->

        <!--Login button End-->

        <div id="navigation" [ngClass]="open ? 'block' : ''">
            <!-- Navigation Menu-->   
            <ul class="navigation-menu justify-end" [ngClass]="navLight ? 'nav-light' : ''">

                <li class="ms-0" [ngClass]="menu === '/' ? 'active' : ''"><a [routerLink]="'/'" class="sub-menu-item">Home</a></li>

                <li class="has-submenu parent-parent-menu-item ms-0 mr-6" [ngClass]="['/games','/games/hot-games','games/slots','/games/live-casino','/games/sports','/games/crash-game','/games/arcade','/games/e-sports','/games/cockfight'].includes(menu) ? 'active' : ''">
                    <a [routerLink]="" (click)="openMenu($event,'/games')">Permainan</a><span class="menu-arrow"></span>
                    <ul class="submenu" [ngClass]="['/games'].includes(subManu) ? 'open' : ''">
                        <li class="ms-0" [ngClass]="menu === '/games/hot-games' ? 'active' : ''"><a [routerLink]="'/games/hot-games'" class="sub-menu-item">Hot Games<span class="bg-gray-50 dark:bg-slate-800 text-[10px] shadow shadow-gray-300 dark:shadow-gray-700 font-bold px-2.5 py-0.5 rounded h-5 ms-1">HOT</span></a></li>
                        <li class="ms-0" [ngClass]="menu === '/games/slots' ? 'active' : ''"><a [routerLink]="'/games/slots'" class="sub-menu-item">Slots</a></li>
                        <li class="ms-0" [ngClass]="menu === '/games/live-casino' ? 'active' : ''"><a [routerLink]="'/games/live-casino'" class="sub-menu-item">Live Casino</a></li>
                        <li class="ms-0" [ngClass]="menu === '/games/sports' ? 'active' : ''"><a [routerLink]="'/games/sports'" class="sub-menu-item">Olahraga</a></li>
                        <li class="ms-0" [ngClass]="menu === '/games/crash-game' ? 'active' : ''"><a [routerLink]="'/games/crash-game'" class="sub-menu-item">Crash Game</a></li>
                        <li class="ms-0" [ngClass]="menu === '/games/arcade' ? 'active' : ''"><a [routerLink]="'/games/arcade'" class="sub-menu-item">Arcade</a></li>
                        <li class="ms-0" [ngClass]="menu === '/games/e-sports' ? 'active' : ''"><a [routerLink]="'/games/e-sports'" class="sub-menu-item">E-Sports</a></li>
                        <li class="ms-0" [ngClass]="menu === '/games/cockfight' ? 'active' : ''"><a [routerLink]="'/games/cockfight'" class="sub-menu-item">Sabung Ayam</a></li>
                    </ul>
                </li>

                <li class="has-submenu parent-parent-menu-item ms-0 mr-6" [ngClass]="['/lottery','/lottery/results','lottery/markets','/lottery/drean-interpretation'].includes(menu) ? 'active' : ''">
                    <a [routerLink]="" (click)="openMenu($event,'/lottery')">Toto</a><span class="menu-arrow"></span>
                    <ul class="submenu" [ngClass]="['/lottery'].includes(subManu) ? 'open' : ''">
                        <li class="ms-0" [ngClass]="menu === '/lottery/results' ? 'active' : ''"><a [routerLink]="'/lottery/results'" class="sub-menu-item"> Hasil</a></li>
                        <li class="ms-0" [ngClass]="menu === '/lottery/markets' ? 'active' : ''"><a [routerLink]="'/lottery/markets'" class="sub-menu-item"> Pasaran</a></li>
                        <li class="ms-0" [ngClass]="menu === '/lottery/dream-interpretation' ? 'active' : ''"><a [routerLink]="'/lottery/dream-interpretation'" class="sub-menu-item"> Buku Mimpi</a></li>
                    </ul>
                </li>

                <li class="has-submenu parent-parent-menu-item ms-0 mr-6" [ngClass]="['/others','/faq'].includes(menu) ? 'active' : ''">
                    <a [routerLink]="" (click)="openMenu($event,'/others')">Lainnya</a><span class="menu-arrow"></span>
                    <ul class="submenu" [ngClass]="['/others'].includes(subManu) ? 'open' : ''">
                        <li class="ms-0" [ngClass]="menu === '/promotion' ? 'active' : ''"><a [routerLink]="'/promotion'" class="sub-menu-item"> Promosi</a></li>
                        <li class="ms-0" [ngClass]="menu === '/faq' ? 'active' : ''"><a [routerLink]="'/faq'" class="sub-menu-item"> FAQ</a></li>
                    </ul>
                </li>
                
      
                <li class="ms-6"><a [routerLink]="'/login'" class="sub-menu-item bg-gradient-to-l from-red-500 to-violet-300 text-transparent bg-clip-text">Login</a></li>
                <li class="ms-6"><a [routerLink]="'/signup'" class="sub-menu-item bg-gradient-to-l from-red-500 to-violet-300 text-transparent bg-clip-text">Daftar</a></li>



              
                <!-- <li class="ms-0" [ngClass]="menu === '/wallet' ? 'active' : ''"><a [routerLink]="'/wallet'" class="sub-menu-item">Wallet</a></li> -->

                <!-- <li class="has-submenu parent-parent-menu-item ms-0" [ngClass]="['/page-item','/aboutus','/creator-item','/creators','/creator-profile','/creator-profile-edit','/become-creator','/blog-item','/blogs','/blog-detail','/auth-item','/login','/signup','/reset-password','/lock-screen','/special-item','/comingsoon','/maintenance','/error','/thankyou','/help-item','/helpcenter-overview','/helpcenter-faqs','/helpcenter-guides','/helpcenter-support','/terms','/privacy'].includes(menu) ? 'active' : ''">
                    <a (click)="openMenu($event,'/page-item')">Pages</a><span class="menu-arrow"></span>
                    <ul class="submenu" [ngClass]="['/page-item','/creator-item','/blog-item','/auth-item','/special-item','/help-item'].includes(subManu) ? 'open' : ''">
                        <li class="ms-0" [ngClass]="menu === '/aboutus' ? 'active' : ''"><a [routerLink]="'/aboutus'" class="sub-menu-item">About Us</a></li>
                        <li class="has-submenu parent-menu-item ms-0" [ngClass]="['/creator-item','/creators','/creator-profile','/creator-profile-edit','/become-creator'].includes(menu) ? 'active' :''"><a [routerLink]="" (click)="openMenu($event,'/creator-item')"> Creator </a><span class="submenu-arrow"></span>
                            <ul class="submenu" [ngClass]="['/creator-item'].includes(subManu) ? 'open' : ''">
                                <li class="ms-0" [ngClass]="menu === '/creators' ? 'active' : ''"><a [routerLink]="'/creators'" class="sub-menu-item"> Creators</a></li>
                                <li class="ms-0" [ngClass]="menu === '/creator-profile' ? 'active' : ''"><a [routerLink]="'/creator-profile'" class="sub-menu-item"> Creator Profile</a></li>
                                <li class="ms-0" [ngClass]="menu === '/creator-profile-edit' ? 'active' : ''"><a [routerLink]="'/creator-profile-edit'" class="sub-menu-item"> Profile Edit</a></li>
                                <li class="ms-0" [ngClass]="menu === '/become-creator' ? 'active' : ''"><a [routerLink]="'/become-creator'" class="sub-menu-item"> Become Creator</a></li>
                            </ul>
                        </li>
                        <li class="has-submenu parent-menu-item ms-0" [ngClass]="['/blog-item','/blogs','/blog-detail'].includes(menu) ? 'active' :''"><a [routerLink]="" (click)="openMenu($event,'/blog-item')"> Blog </a><span class="submenu-arrow"></span>
                            <ul class="submenu" [ngClass]="['/blog-item'].includes(subManu) ? 'open' : ''">
                                <li class="ms-0" [ngClass]="menu === '/blogs' ? 'active' : ''"><a [routerLink]="'/blogs'" class="sub-menu-item"> Blogs</a></li>
                                <li class="ms-0" [ngClass]="menu === '/blog-detail' ? 'active' : ''"><a [routerLink]="'/blog-detail'" class="sub-menu-item"> Blog Detail</a></li>
                            </ul> 
                        </li>
                        <li class="has-submenu parent-menu-item ms-0" [ngClass]="['/auth-item','/login','/signup','/reset-password','/lock-screen'].includes(menu) ? 'active' :''"><a [routerLink]="" (click)="openMenu($event,'/auth-item')"> Auth Pages </a><span class="submenu-arrow"></span>
                            <ul class="submenu" [ngClass]="['/auth-item'].includes(subManu) ? 'open' : ''">
                                <li class="ms-0" [ngClass]="menu === '/login' ? 'active' : ''"><a [routerLink]="'/login'" class="sub-menu-item"> Login</a></li>
                                <li class="ms-0" [ngClass]="menu === '/signup' ? 'active' : ''"><a [routerLink]="'/signup'" class="sub-menu-item"> Signup</a></li>
                                <li class="ms-0" [ngClass]="menu === '/reset-password' ? 'active' : ''"><a [routerLink]="'/reset-password'" class="sub-menu-item"> Forgot Password</a></li>
                                <li class="ms-0" [ngClass]="menu === '/lock-screen' ? 'active' : ''"><a [routerLink]="'/lock-screen'" class="sub-menu-item"> Lock Screen</a></li>
                            </ul> 
                        </li>
                        <li class="has-submenu parent-menu-item ms-0" [ngClass]="['/special-item','/comingsoon','/maintenance','/error','/thankyou'].includes(menu) ? 'active' :''"><a [routerLink]="" (click)="openMenu($event,'/special-item')"> Special </a><span class="submenu-arrow"></span>
                            <ul class="submenu" [ngClass]="['/special-item'].includes(subManu) ? 'open' : ''">
                                <li class="ms-0" [ngClass]="menu === '/comingsoon' ? 'active' : ''"><a [routerLink]="'/comingsoon'" class="sub-menu-item"> Coming Soon</a></li>
                                <li class="ms-0" [ngClass]="menu === '/maintenance' ? 'active' : ''"><a [routerLink]="'/maintenance'" class="sub-menu-item"> Maintenance</a></li>
                                <li class="ms-0" [ngClass]="menu === '/error' ? 'active' : ''"><a [routerLink]="'/error'" class="sub-menu-item"> 404!</a></li>
                                <li class="ms-0" [ngClass]="menu === '/thankyou' ? 'active' : ''"><a [routerLink]="'/thankyou'" class="sub-menu-item"> Thank you</a></li>
                            </ul> 
                        </li>
                        <li class="has-submenu parent-menu-item ms-0" [ngClass]="['/help-item','/helpcenter-overview','/helpcenter-faqs','/helpcenter-guides','/helpcenter-support'].includes(menu) ? 'active' :''"><a [routerLink]="" (click)="openMenu($event,'/help-item')"> Help Center </a><span class="submenu-arrow"></span>
                            <ul class="submenu" [ngClass]="['/help-item'].includes(subManu) ? 'open' : ''">
                                <li class="ms-0" [ngClass]="menu === '/helpcenter-overview' ? 'active' : ''"><a [routerLink]="'/helpcenter-overview'" class="sub-menu-item"> Overview</a></li>
                                <li class="ms-0" [ngClass]="menu === '/helpcenter-faqs' ? 'active' : ''"><a [routerLink]="'/helpcenter-faqs'" class="sub-menu-item"> FAQs</a></li>
                                <li class="ms-0" [ngClass]="menu === '/helpcenter-guides' ? 'active' : ''"><a [routerLink]="'/helpcenter-guides'" class="sub-menu-item"> Guides</a></li>
                                <li class="ms-0" [ngClass]="menu === '/helpcenter-support' ? 'active' : ''"><a [routerLink]="'/helpcenter-support'" class="sub-menu-item"> Support</a></li>
                            </ul> 
                        </li>
                        <li class="ms-0" [ngClass]="menu === '/terms' ? 'active' : ''"><a [routerLink]="'/terms'" class="sub-menu-item">Terms Policy</a></li>
                        <li class="ms-0" [ngClass]="menu === '/privacy' ? 'active' : ''"><a [routerLink]="'/privacy'" class="sub-menu-item">Privacy Policy</a></li>
                    </ul>
                </li> -->
        
                <!-- <li class="ms-0" [ngClass]="menu === '/contact' ? 'active' : ''"><a [routerLink]="'/contact'" class="sub-menu-item">Contact</a></li> -->
            </ul><!--end navigation menu-->
        </div><!--end navigation-->

        </div>

    </div><!--end container-->
</nav><!--end header-->
<!-- End Navbar -->
