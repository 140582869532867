import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { RegularCompFooterComponent } from '../../components/regular-comp-footer/regular-comp-footer.component';
import { RegularCompNavbarComponent } from '../../components/regular-comp-navbar/regular-comp-navbar.component';
import { RegularCompBackToHomeComponent } from '../../components/regular-comp-back-to-home/regular-comp-back-to-home.component';
import { MatIcon } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
  selector: 'app-regular-pages-toto-dream-interpretations',
  templateUrl: './regular-pages-toto-dream-interpretations.component.html',
  styleUrl: './regular-pages-toto-dream-interpretations.component.scss',
  standalone: true,
  imports: [
    MatExpansionModule,
    CommonModule,
    RouterLink,
    RegularCompFooterComponent,
    RegularCompNavbarComponent,
    RegularCompBackToHomeComponent,
    MatIcon,
    MatButtonModule,
    MatCardModule,

  ]
})
export class RegularPagesTotoDreamInterpretationsComponent {

}
