<section class="md:h-screen py-36 flex items-center bg-[url('../../assets/images/cta.jpg')] bg-no-repeat bg-center">
    <div class="absolute top-0 start-0 w-full h-full z-0 pointer-events-none overflow-hidden">
        <!-- <iframe src="https://player.vimeo.com/video/502163294?background=1&autoplay=1&loop=1&byline=0&title=0" class="absolute top-1/2 start-1/2 ltr:-translate-x-1/2 rtl:translate-x-1/2 -translate-y-1/2 w-screen h-[56.25vw] min-h-screen min-w-[177.77vw]"></iframe> -->
    </div>
    <div class="absolute inset-0 bg-gradient-to-b from-transparent to-black"></div>
    <div class="container">
        <div class="flex justify-center">
            <div class="max-w-[800px] w-full h-full m-auto p-6 bg-white dark:bg-slate-900 shadow-md dark:shadow-gray-800 rounded-md">
                <a [routerLink]="'/'">
                    <img [src]="'assets/images/logo-dark.png'" class="mx-auto h-7 block dark:hidden" alt="">
                    <img [src]="'assets/images/logo-light.png'" class="mx-auto h-7 dark:block hidden" alt="">
                </a>
                <h5 class="my-6 text-xl font-semibold">Daftar</h5>
                <form class="text-start">
                    <div class="grid grid-cols-1">

                        <div class="grid grid-cols-12 gap-4 items-center mb-3">
                            <label class="col-span-3 font-semibold text-[15px] break-all" for="RegisterName">Username:</label>
                            <input id="RegisterName" type="text" class="col-span-9 form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0" placeholder="Masukkan Username">
                        </div>

                        <div class="grid grid-cols-12 gap-4 items-center mb-3">
                            <label class="col-span-3 font-semibold text-[15px] break-all" for="RegisterName">Password:</label>
                            <input id="RegisterName" type="password" class="col-span-9 form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0" placeholder="">
                        </div>

                        <div class="grid grid-cols-12 gap-4 items-center mb-3">
                            <label class="col-span-3 font-semibold text-[15px] break-all" for="RegisterName">Konfirm Password:</label>
                            <input id="RegisterName" type="password" class="col-span-9 form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0" placeholder="">
                        </div>

                        <div class="grid grid-cols-12 gap-4 items-center mb-3">
                            <label class="col-span-3 font-semibold text-[15px] break-all" for="RegisterName">Nama Lengkap:</label>
                            <input id="RegisterName" type="text" class="col-span-9 form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0" placeholder="John Smith">
                        </div>
                        
                        <div class="grid grid-cols-12 gap-4 items-center mb-3">
                            <label class="col-span-3 font-semibold text-[15px] break-all" for="RegisterName">Email:</label>
                            <input id="RegisterName" type="text" class="col-span-9 form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0" placeholder="johnsmith@example.com">
                        </div>

                        <div class="grid grid-cols-12 gap-4 items-center mb-3">
                            <label class="col-span-3 font-semibold text-[15px] break-all" for="RegisterName">Whatsapp:</label>
                            <input id="RegisterName" type="text" class="col-span-9 form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0" placeholder="Masukkan Username">
                        </div>

                        <div class="grid grid-cols-12 gap-4 items-center mb-3">
                            <label class="col-span-3 font-semibold text-[15px] break-all" for="RegisterName">Refferal:</label>
                            <input id="RegisterName" type="text" class="col-span-9 form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0" placeholder="Masukkan Referral. Dapat dikosongkan.">
                        </div>



                        <div class="grid grid-cols-12 gap-4 items-center mb-3 mt-3">
                            <label class="col-span-3 font-semibold text-[15px] break-all" for="RegisterName">Bank:</label>
                            <select class="col-span-9 form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0">
                                <option value="">-- Pilih Bank --</option>
                                <option value="autodepo">Automatic Depo</option>
                                <option value="bca">BCA</option>
                                <option value="bca">BNI</option>
                                <option value="bca">BRI</option>
                                <option value="bca">BSI</option>
                                <option value="bca">CIMB</option>
                                <option value="bca">DANA</option>
                                <option value="bca">GOPAY</option>
                                <option value="bca">LINKAJA</option>
                                <option value="bca">MANDIRI</option>
                                <option value="bca">OVO</option>
                                <option value="bca">PERMATA</option>
                            </select>
                            <!-- <input id="RegisterName" type="text" class="col-span-9 form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0" placeholder=""> -->
                        </div>

                        <div class="grid grid-cols-12 gap-4 items-center mb-3">
                            <label class="col-span-3 font-semibold text-[15px] break-all" for="RegisterName">Nomor Bank:</label>
                            <input id="RegisterName" type="text" class="col-span-9 form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0" placeholder="cth. 64252534">
                        </div>

                        <div class="grid grid-cols-12 gap-4 items-center mb-3">
                            <label class="col-span-3 font-semibold text-[15px] break-all" for="RegisterName">Nama Lengkap:</label>
                            <input id="RegisterName" type="text" class="col-span-9 form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0" placeholder="John Smith">
                        </div>

          
                        <div class="mb-4">
                            <input type="submit" class="btn bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-full w-full" value="Register">
                        </div>

                        <div class="text-center">
                            <span class="text-slate-400 me-2">Sudah punya akun ? </span> <a [routerLink]="'/login'" class="text-black dark:text-white font-bold">Login</a>
                        </div>
                    </div>

                    
                </form>
            </div>
        </div>
    </div>
</section>

<app-regular-comp-back-to-home/>
