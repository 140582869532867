import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-regular-comp-faq',
  templateUrl: './regular-comp-faq.component.html',
  styleUrl: './regular-comp-faq.component.scss',
  standalone: true,
  imports : [
    CommonModule,
    RouterLink
  ]
})
export class RegularCompFaqComponent {
  activeTab:any = 1
  
  faqData = [
    {
      id:1,
      title:'Bagaimana Cara Membuat Akun Di Test?',
      desc:`Cara membuat akun di Test sangat mudah, silahkan klik tombol "Daftar" yang terletak di bagian kanan atas pada halaman utama Test. Lalu isi semua informasi yang dibutuhkan secara tepat dan benar. Anda harus mengkonfirmasi bahwa anda setidaknya berusia 18 tahun untuk melengkapi pendaftaran anda.`
    },
    {
      id:2,
      title:'Mata Uang Apa Saja Yang Diterima Oleh Test ?',
      desc:`Mata Uang yang diawakil saat ini adalah: Rupiah (IDR).*`
    },
    {
      id:3,
      title:' Bagaimana Cara Menyetor Dana Ke Akun Test Saya ?',
      desc:`Sebelum anda dapat bertaruh, anda perlu melakukan deposit dana ke akun anda menggunakan salah satu opsi : Bank Lokal atau Online Deposit.`
    },
    {
      id:4,
      title:'Adakah batasan minimal dalam melakukan betting?',
      desc:`Setiap permainan yang disediakan oleh Test memiliki taruhan minimal sesuai permainan yang dipilih. Semua informasi tersedia ketika member sudah masuk ke dalam permainan.`
    },
    {
      id:5,
      title:'Apakah situs kami terpercaya?',
      desc:`Situs kami merupakan situs resmi yang berkantor di Manila, Filipina dimana sebelum kami beroperasi kami sudah mendapatkan lisensi PACGOR sebagai tanda bahwa kami web terpercaya. Seluruh transaksi data keuangan dan privasi anda menjadi salah satu prioritas kami dan tidak ada satupun member kami yang tidak dibayar kemenangannya.`
    },
    
  ]
}
