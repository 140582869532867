<app-regular-comp-navbar/>

<!-- Start Hero -->
<section class="relative table w-full py-36 bg-[url('../../assets/images/bg/bg1.jpg')] bg-bottom bg-no-repeat">
    <div class="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
    <div class="container">
        <div class="grid grid-cols-1 pb-8 text-center mt-10">
            <h3 class="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">Toto</h3>
        </div><!--end grid-->
    </div><!--end container-->
    
    <div class="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
        <ul class="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
           <li class="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><a [routerLink]="'/'">Test</a></li>
            <li class="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white" aria-current="page">Toto - Pasaran</li>
        </ul>
    </div>
</section><!--end section-->
<div class="relative">
    <div class="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
        <svg class="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!-- End Hero -->



<div class="mx-auto z-0 !w-full !max-w-[1150px] overflow-auto mt-5 bg-slate-800 rounded-lg">

    <div class="flex justify-between border-b border-slate-600">
        <div [routerLink]="['/lottery/results']"
            class="text-center w-full hover:bg-purple-950/40 transition-all ease-in duration-200 rounded-lg cursor-pointer font-bold p-2"
           >
            Hasil
        </div>
        <div [routerLink]="['/lottery/markets']"
            class="text-center w-full hover:bg-purple-950/40 transition-all ease-in duration-200 rounded-lg cursor-pointer font-bold p-2"
            routerLinkActive="bg-purple-950">
            Pasaran
        </div>
        <div [routerLink]="['/lottery/dream-interpretation']"
            class="text-center w-full hover:bg-purple-950/40 transition-all ease-in duration-200 rounded-lg cursor-pointer font-bold p-2"
            routerLinkActive="bg-purple-950">
            Buku Mimpi
        </div>
    </div>

    <!-- Markets -->
    <div data-pane="tabpane" class="mx-auto" id="markets">
        <div class="flex justify-between p-4">
            <div>
                <h2 class="border-l-4 border-purple-600 pl-3 uppercase font-bold">Pasaran</h2>
            </div>
        </div>

        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 p-3">

            <div class="wrapper-market disabled">
                <div class="status"></div>
                <div class="flex gap-3 items-center">
                    <div class="w-20">
                        <img class="rounded-full"
                            src="https://vxbrkq1luxtv.gpa2glsjhw.xyz/balak4d/assets/img/l4d/hkliveday.png" alt="">
                    </div>
                    <div class="w-full">
                        <div class="border-b py-1">
                            <h1 class="font-bold uppercase !m-0">Hongkong Live Day Draw</h1>
                            <p class="text-sm font-bold m-0">Setiap Hari</p>
                        </div>
                        <div class="flex mt-2 justify-between">
                            <p class="text-nowrap text-xs m-0"><span class="font-bold">Tutup</span><span>27/10/2024
                                    19.45
                                    WIB</span></p>
                            <p class="flex text-yellow-400 m-0"> <mat-icon
                                    class="material-symbols-outlined text-sm -mt-[.1rem]">schedule</mat-icon> <span
                                    class="text-sm -ml-2">0h 57m</span> </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="wrapper-market">
                <div class="status"></div>
                <div class="flex gap-3 items-center">
                    <div class="w-20">
                        <img class="rounded-full"
                            src="https://vxbrkq1luxtv.gpa2glsjhw.xyz/balak4d/assets/img/l4d/hkliveday.png" alt="">
                    </div>
                    <div class="w-full">
                        <div class="border-b py-1">
                            <h1 class="font-bold uppercase !m-0">Hongkong Live Day Draw</h1>
                            <p class="text-sm font-bold m-0">Setiap Hari</p>
                        </div>
                        <div class="flex mt-2 justify-between">
                            <p class="text-nowrap text-xs m-0"><span class="font-bold">Tutup</span><span>27/10/2024
                                    19.45
                                    WIB</span></p>
                            <p class="flex text-yellow-400 m-0"> <mat-icon
                                    class="material-symbols-outlined text-sm -mt-[.1rem]">schedule</mat-icon> <span
                                    class="text-sm -ml-2">0h 57m</span> </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="wrapper-market">
                <div class="status"></div>
                <div class="flex gap-3 items-center">
                    <div class="w-20">
                        <img class="rounded-full"
                            src="https://vxbrkq1luxtv.gpa2glsjhw.xyz/balak4d/assets/img/l4d/hkliveday.png" alt="">
                    </div>
                    <div class="w-full">
                        <div class="border-b py-1">
                            <h1 class="font-bold uppercase !m-0">Hongkong Live Day Draw</h1>
                            <p class="text-sm font-bold m-0">Setiap Hari</p>
                        </div>
                        <div class="flex mt-2 justify-between">
                            <p class="text-nowrap text-xs m-0"><span class="font-bold">Tutup</span><span>27/10/2024
                                    19.45
                                    WIB</span></p>
                            <p class="flex text-yellow-400 m-0"> <mat-icon
                                    class="material-symbols-outlined text-sm -mt-[.1rem]">schedule</mat-icon> <span
                                    class="text-sm -ml-2">0h 57m</span> </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="wrapper-market">
                <div class="status"></div>
                <div class="flex gap-3 items-center">
                    <div class="w-20">
                        <img class="rounded-full"
                            src="https://vxbrkq1luxtv.gpa2glsjhw.xyz/balak4d/assets/img/l4d/hkliveday.png" alt="">
                    </div>
                    <div class="w-full">
                        <div class="border-b py-1">
                            <h1 class="font-bold uppercase !m-0">Hongkong Live Day Draw</h1>
                            <p class="text-sm font-bold m-0">Setiap Hari</p>
                        </div>
                        <div class="flex mt-2 justify-between">
                            <p class="text-nowrap text-xs m-0"><span class="font-bold">Tutup</span><span>27/10/2024
                                    19.45
                                    WIB</span></p>
                            <p class="flex text-yellow-400 m-0"> <mat-icon
                                    class="material-symbols-outlined text-sm -mt-[.1rem]">schedule</mat-icon> <span
                                    class="text-sm -ml-2">0h 57m</span> </p>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
    <!-- End Of Markets -->

</div>


<app-regular-comp-footer/>