import { CommonModule } from '@angular/common';
import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import {NgClickOutsideDirective} from 'ng-click-outside2';
import { ethers } from 'ethers';

@Component({
  selector: 'app-regular-comp-navbar',
  templateUrl: './regular-comp-navbar.component.html',
  styleUrl: './regular-comp-navbar.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    NgClickOutsideDirective
  ]

})
export class RegularCompNavbarComponent implements OnInit{


  @Input() navLight:any

  scrolled: boolean = false;

  @HostListener("window:scroll", [])

  onWindowScroll() {
      this.scrolled = window.scrollY > 0;
  }


  menu:any
  subManu:any
  constructor(private router : Router){}

  ngOnInit(): void {
    this.menu = this.router.url
    window.scrollTo(0,0)
  }

  openMenu(e:any,item:any){
    if(this.subManu === item){
      this.subManu = ''
    }else{
      this.subManu = item
    }
  }

  open:any

  toggleMenu(e:any){
    e.preventDefault();
    this.open = !this.open
  }

  setUser:boolean = false

  onClickedOutside(e: Event) {
    this.setUser = false
  }


  public account: string | null = null;

  async connectMetaMask() {
    try {
      if (!(window as any).ethereum) {
        alert('MetaMask is not installed. Please install it to continue.');
        return;
      }
      // Correct provider creation in ethers v6
      const provider = new ethers.BrowserProvider((window as any).ethereum); // No more `Web3Provider`
      const accounts = await provider.send('eth_requestAccounts', []);
      this.account = accounts[0];
      console.log('Connected account:', this.account);
    } catch (error) {
      console.error('Failed to connect MetaMask:', error);
    }
  }

}
