<app-regular-comp-navbar/>

<!-- Start Hero -->
<section class="relative table w-full py-36 bg-[url('../../assets/images/bg/bg1.jpg')] bg-bottom bg-no-repeat">
    <div class="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
    <div class="container">
        <div class="grid grid-cols-1 pb-8 text-center mt-10">
            <h3 class="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">Toto</h3>
        </div><!--end grid-->
    </div><!--end container-->
    
    <div class="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
        <ul class="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
           <li class="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><a [routerLink]="'/'">Test</a></li>
            <li class="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white" aria-current="page">Toto - Buku Mimpi</li>
        </ul>
    </div>
</section><!--end section-->
<div class="relative">
    <div class="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
        <svg class="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!-- End Hero -->



<div class="mx-auto z-0 !w-full !max-w-[1150px] overflow-auto mt-5 bg-slate-800 rounded-lg">

    <div class="flex justify-between border-b border-slate-600">
        <div [routerLink]="['/lottery/results']"
            class="text-center w-full hover:bg-purple-950/40 transition-all ease-in duration-200 rounded-lg cursor-pointer font-bold p-2"
           >
            Hasil
        </div>
        <div [routerLink]="['/lottery/markets']"
            class="text-center w-full hover:bg-purple-950/40 transition-all ease-in duration-200 rounded-lg cursor-pointer font-bold p-2"
            routerLinkActive="bg-purple-950">
            Pasaran
        </div>
        <div [routerLink]="['/lottery/dream-interpretation']"
            class="text-center w-full hover:bg-purple-950/40 transition-all ease-in duration-200 rounded-lg cursor-pointer font-bold p-2"
            routerLinkActive="bg-purple-950">
            Buku Mimpi
        </div>
    </div>


    <!-- Dream Interpretation -->
    <div data-pane="tabpane" class="mx-auto" id="dream-interpretation">
        <div class="flex justify-between p-4">
            <div>
                <h2 class="border-l-4 border-purple-600 pl-3 uppercase font-bold">Buku Mimpi</h2>
            </div>
        </div>

        <div class="p-3">

            <!-- Filter -->
            <div class="bg-slate-900 rounded-lg p-3 flex flex-col md:flex-row gap-2 w-full items-center">
                <div class="flex gap-2 md:w-[29rem] w-full items-center">
                    <div class="w-full">
                        <select type="text" class="input-custom-1 w-full">
                            <option value="2d">2D</option>
                        </select>
                    </div>
                    <div class="w-full">
                        <select type="text" class="input-custom-1 w-full">
                            <option value="kata-kunci">Kata Kunci</option>
                        </select>
                    </div>
                </div>
                <div class="relative w-full">
                    <input type="search" name="search-text" class="input-custom-1 w-full"
                        placeholder="Masukan kata kunci">
                    
                </div>
                <button mat-button class="bg-yellow-600 rounded-lg text-white md:w-72 w-full">
                    <mat-icon class="material-symbols-outlined absolute right-3 top-3">search</mat-icon>
                    Cari arti
                    mimpi</button>
            </div>

            <!-- Table Dream -->
            <div class="overflow-x-auto mt-3 rounded-lg bg-gray-700">
                <table class="table-striped w-full text-xs text-left text-gray-700 border-none">
                    <thead class="bg-slate-900 text-gray-200">
                        <tr>
                            <th scope="col" class="px-2 py-3 text-center">No</th>
                            <th scope="col" class="px-6 py-3">Nama</th>
                            <th scope="col" class="px-6 py-3">Kota</th>
                        </tr>
                    </thead>
                    <tbody class="bg-slate-800">
                        <tr>
                            <td class="px-2 py-2 text-center">1</td>
                            <td class="px-4 py-2 text-wrap">Penyair - Tapir - Sempritan - Rembulan - Tanggalan - Kumbo
                                KARN0</td>
                            <td class="px-3 py-2">00 (97-48-64-98)</td>
                        </tr>
                        <tr>
                            <td class="px-2 py-2 text-center">2</td>
                            <td class="px-4 py-2 text-wrap">Penyair - Tapir - Sempritan - Rembulan - Tanggalan - Kumbo
                                KARN0</td>
                            <td class="px-3 py-2">00 (97-48-64-98)</td>
                        </tr>
                        <tr>
                            <td class="px-2 py-2 text-center">3</td>
                            <td class="px-4 py-2 text-wrap">Penyair - Tapir - Sempritan - Rembulan - Tanggalan - Kumbo
                                KARN0</td>
                            <td class="px-3 py-2">00 (97-48-64-98)</td>
                        </tr>
                        <tr>
                            <td class="px-2 py-2 text-center">4</td>
                            <td class="px-4 py-2 text-wrap">Penyair - Tapir - Sempritan - Rembulan - Tanggalan - Kumbo
                                KARN0</td>
                            <td class="px-3 py-2">00 (97-48-64-98)</td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    </div>
    <!-- End Of Dream Interpretation -->

</div>


<app-regular-comp-footer/>