import { CommonModule } from '@angular/common';
import { Component, signal } from '@angular/core';
import { RouterLink } from '@angular/router';
import { RegularCompFooterComponent } from '../../components/regular-comp-footer/regular-comp-footer.component';
import { RegularCompNavbarComponent } from '../../components/regular-comp-navbar/regular-comp-navbar.component';
import { RegularCompTotoNumberComponent } from '../../components/regular-comp-toto-number/regular-comp-toto-number.component';
import { MatIcon } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
  selector: 'app-regular-pages-toto-results',
  templateUrl: './regular-pages-toto-results.component.html',
  styleUrl: './regular-pages-toto-results.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    RegularCompFooterComponent,
    RegularCompNavbarComponent,
    RegularCompTotoNumberComponent,
    MatIcon,
    MatCardModule,
    MatButtonModule,
    MatExpansionModule
  ]
})
export class RegularPagesTotoResultsComponent {
  readonly expansionPanelOpenState = signal(false);
}
