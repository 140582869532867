import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

import * as feather from 'feather-icons';

@Component({
  selector: 'app-regular-comp-footer',
  templateUrl: './regular-comp-footer.component.html',
  styleUrl: './regular-comp-footer.component.scss',
  standalone: true,
  imports : [
    CommonModule,
    RouterLink
  ]
})
export class RegularCompFooterComponent {
  ngAfterViewInit() {
    feather.replace();
  }
  date:any
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.date = new Date().getFullYear()
  }

  link1 = [
    {
      title:'Home',
      link:'/'
    },
    {
      title:'Hot Games',
      link:'/games/hot-games'
    },
    {
      title:'Keluaran Togel',
      link:'/lottery/results'
    },
    {
      title:'Pasaran Togel',
      link:'/lottery/markets'
    },
    {
      title:'Buku Mimpi',
      link:'/lottery/dream-interpretation'
    },
  ]
  link2 = [
    {
      title:'Login',
      link:'/login'
    },
    {
      title:'Tentang Kita',
      link:'/aboutus'
    },
    {
      title:'Syarat dan Ketentuan',
      link:'/terms'
    },
    {
      title:'Responsible Gambling',
      link:'/privacy'
    },
    {
      title:'Kontak',
      link:'/contact'
    },
  ]

  social = [
    {
      icon:'uil uil-facebook-f',
      link:'https://www.facebook.com/test'
    },
    {
      icon:'uil uil-instagram',
      link:'https://www.instagram.com/test'
    },
    {
      icon:'uil uil-twitter',
      link:'https://twitter.com/test'
    },
    {
      icon:'uil uil-envelope',
      link:'mailto:support@testmail.com'
    },
  ]
}
