import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-regular-pages',
  templateUrl: './regular-pages.component.html',
  styleUrl: './regular-pages.component.scss',
  standalone: true,
  imports: [
    RouterModule
  ]
})
export class RegularPagesComponent implements OnInit{

  ngOnInit(): void {
    this.setDarkTheme()
  }

  setDarkTheme(){
    const htmlTag = document.documentElement;
    htmlTag.className = 'dark'
  }

  setLightTheme(){
    const htmlTag = document.documentElement;
    htmlTag.className = 'light'
  }

}
