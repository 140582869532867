[
    {
        "id":1,
        "image":"assets/images/lott/hungary.png",
        "title":"Hungary Pools",
        "close":"19:30",
        "latest_date":"27/10/2024 20:27 ",
        "latest_num":"153456",
        "draw_number":"345"
    },
    {
        "id":2,
        "image":"assets/images/lott/bulgaria.png",
        "title":"Bulgaria Pools",
        "close":"19:30",
        "latest_date":"27/10/2024 21:30 ",
        "latest_num":"765476"
    },
    {
        "id":3,
        "image":"assets/images/lott/cuba.png",
        "title":"Cuba Pools",
        "close":"19:30",
        "latest_date":"27/10/2024 22:00 ",
        "latest_num":"859345",
        "draw_number":"345"
    },
    {
        "id":4,
        "image":"assets/images/lott/phuket.png",
        "title":"Phuket Lottery",
        "close":"19:30",
        "latest_date":"27/10/2024 10:45 ",
        "latest_num":"195345",
        "draw_number":"345"
    },
    {
        "id":5,
        "image":"assets/images/lott/shanghai.png",
        "title":"Toto Shanghai",
        "close":"19:30",
        "latest_date":"27/10/2024 20:10 ",
        "latest_num":"1675",
        "draw_number":"345"
    },
    {
        "id":6,
        "image":"assets/images/lott/slovakia.png",
        "title":"Slovakia Lottery",
        "close":"19:30",
        "latest_date":"27/10/2024 17.00 ",
        "latest_num":"16345676",
        "draw_number":"345"
    },
    {
        "id":7,
        "image":"assets/images/lott/hungary.png",
        "title":"Hungary Pools - Ex",
        "close":"19:30",
        "latest_date":"27/10/2024 20:45 ",
        "latest_num":"125674",
        "draw_number":"345"
    },
    {
        "id":8,
        "image":"assets/images/lott/bulgaria.png",
        "title":"Bulgaria Pools - Ex",
        "close":"19:30",
        "latest_date":"27/10/2024 20:20 ",
        "latest_num":"326753",
        "draw_number":"345"
    },
    {
        "id":9,
        "image":"assets/images/lott/phuket.png",
        "title":"Phuket Lottery - Ex",
        "close":"19:30",
        "latest_date":"27/10/2024 20:21 ",
        "latest_num":"123676",
        "draw_number":"345"
    },
    {
        "id":10,
        "image":"assets/images/lott/shanghai.png",
        "title":"Toto Shanghai -Ex",
        "close":"19:30",
        "latest_date":"27/10/2024 10:10 ",
        "latest_num":"4565",
        "draw_number":"345"
    },
    {
        "id":11,
        "image":"assets/images/lott/hungary.png",
        "title":"Hungary Pools - Night",
        "close":"19:30",
        "latest_date":"27/10/2024 18.00 ",
        "latest_num":"125676",
        "draw_number":"345"
    },
    {
        "id":12,
        "image":"assets/images/lott/bulgaria.png",
        "title":"Bulgaria Pools - Night",
        "close":"19:30",
        "latest_date":"27/10/2024 19.00 ",
        "latest_num":"464678",
        "draw_number":"345"
    },
    {
        "id":1,
        "image":"assets/images/lott/hungary.png",
        "title":"Hungary Pools",
        "close":"19:30",
        "latest_date":"27/10/2024 20:27 ",
        "latest_num":"153456",
        "draw_number":"345"
    },
    {
        "id":2,
        "image":"assets/images/lott/bulgaria.png",
        "title":"Bulgaria Pools",
        "close":"19:30",
        "latest_date":"27/10/2024 21:30 ",
        "latest_num":"765476",
        "draw_number":"345"
    },
    {
        "id":3,
        "image":"assets/images/lott/cuba.png",
        "title":"Cuba Pools",
        "close":"19:30",
        "latest_date":"27/10/2024 22:00 ",
        "latest_num":"859345",
        "draw_number":"345"
    },
    {
        "id":4,
        "image":"assets/images/lott/phuket.png",
        "title":"Phuket Lottery",
        "close":"19:30",
        "latest_date":"27/10/2024 10:45 ",
        "latest_num":"195345",
        "draw_number":"345"
    },
    {
        "id":5,
        "image":"assets/images/lott/shanghai.png",
        "title":"Toto Shanghai",
        "close":"19:30",
        "latest_date":"27/10/2024 20:10 ",
        "latest_num":"1675",
        "draw_number":"345"
    },
    {
        "id":6,
        "image":"assets/images/lott/slovakia.png",
        "title":"Slovakia Lottery",
        "close":"19:30",
        "latest_date":"27/10/2024 17.00 ",
        "latest_num":"16345676",
        "draw_number":"345"
    },
    {
        "id":7,
        "image":"assets/images/lott/hungary.png",
        "title":"Hungary Pools - Ex",
        "close":"19:30",
        "latest_date":"27/10/2024 20:45 ",
        "latest_num":"125674",
        "draw_number":"345"
    },
    {
        "id":8,
        "image":"assets/images/lott/bulgaria.png",
        "title":"Bulgaria Pools - Ex",
        "close":"19:30",
        "latest_date":"27/10/2024 20:20 ",
        "latest_num":"326753",
        "draw_number":"345"
    },
    {
        "id":9,
        "image":"assets/images/lott/phuket.png",
        "title":"Phuket Lottery - Ex",
        "close":"19:30",
        "latest_date":"27/10/2024 20:21 ",
        "latest_num":"123676",
        "draw_number":"345"
    },
    {
        "id":10,
        "image":"assets/images/lott/shanghai.png",
        "title":"Toto Shanghai -Ex",
        "close":"19:30",
        "latest_date":"27/10/2024 10:10 ",
        "latest_num":"4565",
        "draw_number":"345"
    },
    {
        "id":11,
        "image":"assets/images/lott/hungary.png",
        "title":"Hungary Pools - Night",
        "close":"19:30",
        "latest_date":"27/10/2024 18.00 ",
        "latest_num":"125676",
        "draw_number":"345"
    },
    {
        "id":12,
        "image":"assets/images/lott/bulgaria.png",
        "title":"Bulgaria Pools - Night",
        "close":"19:30",
        "latest_date":"27/10/2024 19.00 ",
        "latest_num":"464678",
        "draw_number":"345"
    }
]