import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { RegularCompFooterComponent } from '../../components/regular-comp-footer/regular-comp-footer.component';
import { RegularCompNavbarComponent } from '../../components/regular-comp-navbar/regular-comp-navbar.component';
import { RegularCompBackToHomeComponent } from '../../components/regular-comp-back-to-home/regular-comp-back-to-home.component';
import { CarouselModule } from 'primeng/carousel';
import { ButtonModule as PrimeButton } from 'primeng/button';
import { TagModule } from 'primeng/tag';
import { MatIcon } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'app-regular-pages-toto-markets',
  templateUrl: './regular-pages-toto-markets.component.html',
  styleUrl: './regular-pages-toto-markets.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    RegularCompFooterComponent,
    RegularCompNavbarComponent,
    RegularCompBackToHomeComponent,
    MatIcon
  ]
})
export class RegularPagesTotoMarketsComponent {

  constructor(private readonly router : Router){

  }

  navigateToURL(input:string){
    this.router.navigateByUrl(input)
  }


  walletData = [
    {
      image:'assets/images/lott/bulgaria.png',
      name:'Bulgaria Pools',
      desc:'Learn about how to get the wallet and much more clicking',
      open: 'Setiap hari',
      time: "21.50",
      remain: "1h20m",
    },
    {
      image:'assets/images/lott/cuba.png',
      name:'Cuba Pools',
      desc:'Learn about how to get the wallet and much more clicking',
      open: 'Setiap hari',
      time: "13.25",
      remain: "4h37m",
    },
    {
      image:'assets/images/lott/hungary.png',
      name:'Hungary Pools',
      desc:'Learn about how to get the wallet and much more clicking',
      open: 'Setiap hari',
      time: "17.00",
      remain: "1h20m",
    },
    {
      image:'assets/images/lott/phuket.png',
      name:'Phuket Lottery',
      desc:'Learn about how to get the wallet and much more clicking',
      open: 'Setiap hari',
      time: '19.00',
      remain: "9h10m",
    },
    {
      image:'assets/images/lott/shanghai.png',
      name:'Toto Shanghai',
      desc:'Learn about how to get the wallet and much more clicking',
      open: 'Setiap hari',
      time: "21.00",
      remain: "7h10m",
    },
    {
      image:'assets/images/lott/slovakia.png',
      name:'Slovakia Lottery',
      desc:'Learn about how to get the wallet and much more clicking',
      open: 'Setiap hari',
      time: "22.00",
      remain: "0h9m",
    },
  ]

  show:boolean = false
}
